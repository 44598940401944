import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
// const DashboardLayout = React.lazy(() => "src/layouts/DashboardLayout");

export const routes = [
  // Validator list
  {
    exact: true,
    path: "/",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StakingUnstaking/index")),
  },
  {
    exact: true,
    path: "/staking",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StakingUnstaking/Staking")),
  },
  {
    exact: true,
    path: "/user-profile",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StakingUnstaking/UserProfile")
    ),
  },
  {
    exact: true,
    path: "/Unstaking",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StakingUnstaking/Unstaking")),
  },
  {
    exact: true,
    path: "/createNode",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StakingUnstaking/CreateNode")
    ),
  },
  {
    exact: true,
    path: "/stackbalance",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StakingUnstaking/GetStakeBalance")
    ),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
