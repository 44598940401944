import React, { Suspense, Fragment, useMemo } from "react";
import { Router, Switch, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
import AuthContext from "src/context/Auth";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "src/theme";
import { Toaster } from "react-hot-toast";

const history = createBrowserHistory();

function App() {
  const theme = useMemo(() => createTheme(), []);

  return (
    <div className="App">
      <Toaster
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme.palette.type}
      />
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <Router history={history}>
              <RenderRoutes data={routes} />
            </Router>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes({ data }) {
  const location = useLocation();

  const routeComponents = useMemo(() => {
    return data.map((route, i) => {
      const Component = route.component;
      const Guard = route.guard ? AuthGuard : Fragment;
      const Layout = route.layout || Fragment;
      return (
        <Route
          exact={route.exact}
          key={i}
          path={route.path}
          render={(props) => (
            <Guard>
              <Layout>
                {route.routes ? (
                  <RenderRoutes data={route.routes} />
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            </Guard>
          )}
        />
      );
    });
  }, [data]);

  return (
    <Suspense fallback={<PageLoading />}>
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={800}>
          <Switch location={location}>{routeComponents}</Switch>
        </CSSTransition>
      </TransitionGroup>
    </Suspense>
  );
}
